<template>
  <v-card-text>
    <v-data-iterator :items="imagesWorks" :items-per-page="10" :page="1">
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.id"
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <CardImagesWorks
              :item="item"
              :srcImage="loadImage(item.img_works)"
              @edit="$emit('edit', item)"
              @remove="$emit('remove', item)"
            ></CardImagesWorks>

          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-card-text>
</template>

<script>
import CardImagesWorks from "@/components/cards/CardImagesWorks.vue";
import { loadAPIImage } from "@/utils/images";
export default {
  components: {
    CardImagesWorks,
  },
  props: {
    imagesWorks: Array,
  },
  methods: {
    loadImage(src) {
      return loadAPIImage(src);
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <v-card elevation="0">
    <HeaderView title="Imagenes de las  obras" @openToAdd="openDialog" :noSearch="true"></HeaderView>
    <ListImagesWorks :imagesWorks="imagesWorks" @edit="openEdit" @remove="openRemove" />
    <WDialog
      width="500"
      :dialog="dialog"
      :closeDialog="closeDialog"
      :title="titleDialog"
      :subtitle="subtitleDialog"
    >
      <template v-slot:form-dialog>
        <FormImagesWorks
          v-if="dialog"
          :loading="loading"
          :payloadEdited="imagesWorksEdited"
          @beforeUpdate="updateImagesWorks"
          @beforeCreate="createImagesWorks"
          :idWorks="idWorks"
          >
        </FormImagesWorks>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import HeaderView from "@/components/HeaderView.vue";
import ListImagesWorks from "./ListImagesWorks.vue";
import WDialog from "@/components/dialogs/WDialog.vue";
import FormImagesWorks from "@/components/forms/FormImagesWorks.vue";
import useImagesWorks from "@/composables/useImagesWorks";

export default {
  components: {
    FormImagesWorks,
    HeaderView,
    ListImagesWorks,
    WDialog,
  },
  props: {
    idWorks: Number
  },
  setup(props) {
    // Composition API
    const {
      dialog,
      imagesWorksEdited,
      loading,
      imagesWorks,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      closeDialog,
      // crud
      getImagesWorks,
      createImagesWorks,
      updateImagesWorks,
      openRemove,
    } = useImagesWorks(props.idWorks);

    return {
      dialog,
      imagesWorksEdited,
      loading,
      imagesWorks,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      closeDialog,
      // crud
      getImagesWorks,
      createImagesWorks,
      updateImagesWorks,
      openRemove,
    };
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formWorks" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="3">
            <label for="title_works">Titulo</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              autocomplete="off"
              id="title_works"
              v-model="works.title_works"
              outlined
              dense
              placeholder="Nombre de la obra"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <label for="simple_description">Descripción Corta</label>
          </v-col>

          <v-col cols="12" md="12" class="pb-0">
            <v-textarea
              autocomplete="off"
              id="simple_description"
              v-model="works.simple_description"
              outlined
              dense
              placeholder="Descripción corta del servicio"
              rows="6"
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="12">
            <label for="description">Descripción</label>
          </v-col>

          <v-col cols="12" md="12" class="pb-0">
            <v-textarea
              autocomplete="off"
              id="description"
              v-model="works.description"
              outlined
              dense
              placeholder="Descripción del servicio"
              rows="10"
              :rules="required"
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="3">
            <label for="location">Buscar Ubicación</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-autocomplete
              id="location"
              v-model="selectedPlace"
              outlined
              dense
              placeholder="Ingresa la dirección"
              :loading="loadingFindAddress"
              :search-input.sync="findAddressInPlaces"
              :items="foundedAddress"
              item-text="description"
              item-value="place_id"
              :filter="filterObject"
              clearable
              return-object
              @change="getLocationByPlaceId"
              @click:clear="clearMap"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12">
            <span>Señala la ubicación del lugar de arrendamiento</span>
          </v-col>

          <v-col cols="12" md="12" class="mb-2">
            <GmapMap @click="setLocation" :center="center" :zoom="zoomMap" style="width: 100%; height: 400px">
              <GmapMarker v-if="works.location" :position="works.location" />
            </GmapMap>
          </v-col>

          <v-col cols="12" md="3">
            <label for="IsPrimary">Obra destacada</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <input type="checkbox" id="IsPrimary" v-model="works.IsPrimary" />{{ works.IsPrimary ? ' Si' : ' No' }}
          </v-col>

          <v-col cols="12" md="3">
            <label for="isProgress">Obra en progreso</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <input type="checkbox" id="isProgress" v-model="works.InProgress" />{{
              works.InProgress ? ' En ejecuccion' : ' Ejecutado'
            }}
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>

          <v-col v-if="idWorks">
            <ImagesWorks :idWorks="idWorks"></ImagesWorks>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import ImagesWorks from '@/views/pages/imagesWorks/ImagesWorks.vue'
import { findAddress, getContentByPlaceId } from '@/composables/useGoogleMaps'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    FormTemplate,
    ImagesWorks,
  },
  props: {
    worksEdited: Object,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      works: this.worksEdited
        ? { ...this.worksEdited }
        : {
            IsPrimary: false,
            IsProgress: false,
          },
      imagesWorks: [],
      idWorks: this.worksEdited ? this.worksEdited.id : null,

      center:
        this.worksEdited && this.worksEdited.location
          ? this.worksEdited.location
          : { lat: -12.047846, lng: -77.0328566 },
      defaultCenter: { lat: -12.047846, lng: -77.0328566 },
      location: null,
      zoomMap: this.worksEdited ? 19 : 12,

      selectedPlace: null,
      loadingFindAddress: false,
      findAddressInPlaces: null,
      foundedAddress: [],
    }
  },
  watch: {
    findAddressInPlaces(val) {
      if (!val || val.length <= 3) return
      this.loadingFindAddress = true
      if (this.timeOut) clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        this.findAddressMethod(val)
        this.timeOut = null
      }, 500)
      this.loadingFindAddress = false
    },
  },
  methods: {
    validateForm() {
      return this.$refs.formWorks.validate()
    },
    reset() {
      this.works.title_works = ''
      this.works.simple_description = ''
      this.works.description = ''
      this.works.ubication = ''
      this.works.IsPrimary = ''
      this.works.InProgress = ''
      this.$refs.formWorks.resetValidation()
    },
    async findAddressMethod(search) {
      this.foundedAddress = await findAddress(search)
    },
    saveForm() {
      if (!this.validateForm()) return
      let DTO = { ...this.works }
      if (this.worksEdited) {
        // // console.log('actualizar', DTO)
        this.$emit('beforeUpdate', DTO)
      } else {
        // // console.log('crear', DTO)
        this.$emit('beforeCreate', DTO)
      }
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
    clearMap() {
      this.center = this.defaultCenter
      this.location = null
      this.zoomMap = 12
    },
    async getLocationByPlaceId() {
      if (!this.selectedPlace) return
      const location = await getContentByPlaceId(this.selectedPlace.place_id)

      this.location = location.geometry.location
      this.center = location.geometry.location

      if (this.worksEdited) {
        this.works.location = { ...this.works.location, ...location.geometry.location }
      } else {
        this.works.location = location.geometry.location
      }

      this.zoomMap = 18
    },
    setLocation(location) {
      const obj = { lat: location.latLng.lat(), lng: location.latLng.lng() }
      if (this.worksEdited) {
        this.works.location = { ...this.works.location, ...obj }
      } else {
        this.works.location = obj
      }
      this.location = obj
      this.center = obj
      this.zoomMap = 18
    },
    filterObject(item, queryText, itemText) {
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-service {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #00aca0;
  padding: 20px;
}

.icon-service img {
  max-width: 80px;
  max-height: 80px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

import store from "@/store/index"

export const findAddress = async (address = '') => {
    const { data, status } = await store.dispatch("findAddress", address);
    return data.predictions;
}

export const getContentByPlaceId = async (placeId) => {
    const { data, status } = await store.dispatch("getContentByPlaceId", placeId);
    return data.result;
}


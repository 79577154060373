<template>
  <v-card>
    <WorksHeader @openToAdd="openDialog" @search="changeSearch"></WorksHeader>
    <DatatableWorks
      :works="works"
      :loading="loading"
      :search="search"
      @edit="openEdit"
      @remove="openRemove"
    ></DatatableWorks>
    <WDialog width="1000" :dialog="dialog" :closeDialog="closeDialog" :title="titleDialog" :subtitle="subtitleDialog">
      <template v-slot:form-dialog>
        <FormWorks
          v-if="dialog"
          :loading="loading"
          :worksEdited="worksEdited"
          @beforeUpdate="updateWorks"
          @beforeCreate="createWorks"
        ></FormWorks>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import DatatableWorks from './DatatableWorks.vue'
import FormWorks from '@/components/forms/FormWorks.vue'
import WorksHeader from './WorksHeader.vue'
import WDialog from '@/components/dialogs/WDialog.vue'
import useWorks from '@/composables/useWorks'

export default {
  components: {
    DatatableWorks,
    FormWorks,
    WorksHeader,
    WDialog,
  },
  setup() {
    // Composition API
    const {
      dialog,
      worksEdited,
      works,
      search,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createWorks,
      updateWorks,
    } = useWorks()

    return {
      dialog,
      works,
      search,
      loading,
      worksEdited,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createWorks,
      updateWorks,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>

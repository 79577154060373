<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="works"
      :loading="loading"
      item-key="name"
      class="table-rounded datatable-height"
      fixed-header
      disable-sort
    >
      <!-- icon -->
      <template #[`item.image`]="{ item }">
        <div class="icon-service center-sub-items">
          <img draggable="false" :src="loadImage(item.image)" :alt="item.title_works" />
        </div>
      </template>
      <!-- name -->
      <template #[`item.text`]="{ item }">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.text }}</span>
        </div>
      </template>
      <template #[`item.actions`]="{ item }">
        <div class="d-flex">
          <CrudButtons @edit="$emit('edit', item)" @remove="$emit('remove', item)"></CrudButtons>
        </div>
      </template>

      <template #[`item.IsPrimary`]="{ item }">
        <div>
          <span>{{item.IsPrimary ? " Si" : " No"}}</span>
        </div>
      </template>

      <template #[`item.InProgress`]="{ item }">
        <div>
          <span>{{item.InProgress ? " En ejecuccion" : " Ejecutado"}}</span>
        </div>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import CrudButtons from '@/components/buttons/CrudButtons.vue'
import { loadAPIImage } from '@/utils/images'
import imdDefault from '@/assets/images/logos/aviato.png'

export default {
  components: {
    CrudButtons,
  },
  props: {
    works: Array,
    search: String,
    loading: Boolean,
  },
  setup() {
    const statusColor = {
      Activo: 'primary',
      Inactivo: 'error',
    }

    const loadImage = img => {
      if (!img) return imdDefault;
      return loadAPIImage(img.img_works)
    }

    return {
      loadImage,
      headers: [
        { text: 'Imagen', value: 'image'},
        { text: 'Titulo', value: 'title_works' },
        { text: 'Obra destacada', value: 'IsPrimary' },
        { text: 'Obra en progreso', value: 'InProgress' },
        { value: 'actions', align: 'right' },
      ],
      status: {
        1: 'Activo',
        2: 'Inactivo',
      },
      statusColor,
    }
  },
}
</script>

<style lang="scss" scoped>
.icon-service {
  margin: 10px;
}

.icon-service img {
  max-width: 150px;
  max-height: 150px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

import { render, staticRenderFns } from "./ImagesWorks.vue?vue&type=template&id=bb77df32&scoped=true&"
import script from "./ImagesWorks.vue?vue&type=script&lang=js&"
export * from "./ImagesWorks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb77df32",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})

<template>
  <div>
    <div class="row-title-btn">
      <v-card-title v-show="!searchActive">OBRAS</v-card-title>
      <div class="controls-actions">
        <div class="searcher-control">
          <div :class="`input-control ${searchActive ? 'active' : ''}`">
            <v-text-field
              v-model="keyword"
              @focus="changeSearch(true)"
              @keyup="$emit('search', keyword)"
              rounded
              dense
              outlined
              :prepend-inner-icon="icons.mdiMagnify"
              class="app-bar-search flex-grow-0"
              hide-details
            ></v-text-field>
            <v-btn
              @click="
                () => {
                  changeSearch(false);
                  $emit('search', keyword);
                }
              "
              v-show="searchActive"
              icon
            >
              <v-icon size="22">{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </div>
        </div>
        <v-btn @click="$emit('openToAdd')" v-show="!searchActive" icon class="ml-2">
          <v-icon size="24">{{ icons.mdiPlus }}</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiPlus, mdiMagnify, mdiClose } from "@mdi/js";
import { ref } from "@vue/composition-api";
export default {
  setup() {
    const searchActive = ref(false);
    const keyword = ref("");
    const clearSearch = () => {
      keyword.value = "";
    };
    return {
      icons: {
        mdiPlus,
        mdiMagnify,
        mdiClose,
      },
      searchActive,
      keyword,
      clearSearch,
      changeSearch(val) {
        if (!val) {
          clearSearch();
        }
        searchActive.value = val;
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.row-title-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  position: relative;
  min-height: 60px;
}
.input-control {
  width: 50px;
  max-height: 60px;
  height: 60px;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  top: 0;
  transition: all ease-in-out 0.25s;
  transform-origin: initial;
  .app-bar-search {
    width: 100%;
  }
}
.input-control.active {
  position: absolute;
  width: 100%;
  right: 0;
  z-index: 5;
  padding: 4px 16px;
  animation: searcher ease-in-out 0.25s;
}
@keyframes searcher {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.controls-actions {
  display: flex;
  align-items: center;
}
</style>

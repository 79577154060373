var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"table-rounded datatable-height",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.works,"loading":_vm.loading,"item-key":"name","fixed-header":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"icon-service center-sub-items"},[_c('img',{attrs:{"draggable":"false","src":_vm.loadImage(item.image),"alt":item.title_works}})])]}},{key:"item.text",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.text))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('CrudButtons',{on:{"edit":function($event){return _vm.$emit('edit', item)},"remove":function($event){return _vm.$emit('remove', item)}}})],1)]}},{key:"item.IsPrimary",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(item.IsPrimary ? " Si" : " No"))])])]}},{key:"item.InProgress",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(item.InProgress ? " En ejecuccion" : " Ejecutado"))])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
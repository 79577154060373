import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useWorks = () => {

  // base data
  const dialog = ref(false);
  const worksEdited = ref(null);
  const works = ref([]);
  const search = ref("");
  const loading = ref(false);

  // computed properties
  const titleDialog = computed(() =>
    worksEdited.value ? "Editar obra" : "Agregar obra"
  );
  const subtitleDialog = computed(() =>
    worksEdited.value ?
      "Rellene los campos correctamente para modificar los datos de la obra" :
      "Rellene los campos correctamente para validar los datos de la obra"
  );

  const closeDialog = () => {
    dialog.value = false;
    worksEdited.value = null;
  }

  // methods
  const getWorksList = async () => {
    const { data, status } = await store.dispatch("fetchWorks")
    if (status != 200) return;
    works.value = data;
  }

  const createWorks = async (works) => {
    loading.value = true;
    const { data, status } = await store.dispatch("createWorks", works)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getWorksList();
    closeDialog();
  }

  const updateWorks = async (works) => {
    loading.value = true;
    const { data, status } = await store.dispatch("updateWorks", works)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getWorksList();
    closeDialog();
  }

  const removeWorks = async (works) => {
    const val = await store.dispatch("generateConfirmDialog", {});
    if (!val) return;
    const { data, status } = await store.dispatch("removeWorks", works)
    if (status != 200 && status != 201 && status != 204) return;
    await getWorksList();
  }

  getWorksList();

  return {
    dialog,
    worksEdited,
    works,
    search,
    loading,
    // computed
    titleDialog,
    subtitleDialog,
    // methods
    changeSearch(ev) {
      search.value = ev
    },
    openDialog() {
      dialog.value = true;
    },
    openEdit(works) {
      let location = works.location
      if (!!location) {
        location = {
          ...location,
          lat: +location.lat,
          lng: +location.lng
        }
      }
      worksEdited.value = { ...works, location };
      dialog.value = true;
    },
    openRemove: removeWorks,
    closeDialog,
    createWorks,
    updateWorks,
  };
}

export default useWorks;

import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useImagesWorks = (id_work) => {

  // base data
  const dialog = ref(false);
  const imagesWorksEdited = ref(null);
  const imagesWorks = ref([]);
  const loading = ref(false);

  // computed properties
  const titleDialog = computed(() => imagesWorksEdited.value ? "Editar imagen" : "Agregar imagen");
  const subtitleDialog = computed(() =>
    imagesWorksEdited.value ?
      "Rellene los campos correctamente para modificar los datos de la imagen" :
      "Rellene los campos correctamente para agregar una nueva imagen"
  );

  // methods
  const openDialog = () => {
    dialog.value = true;
  }
  const openEdit = (imagesWorks) => {
    dialog.value = true;
    imagesWorksEdited.value = { ...imagesWorks };
  }
  const closeDialog = () => {
    dialog.value = false;
    imagesWorksEdited.value = null;
  }

  const createImagesWorks = async (imagesWorks) => {
    loading.value = true;
    const { data, status } = await store.dispatch("createImagesWorks", imagesWorks)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getListByIdWorks();
    closeDialog();
  }

  const updateImagesWorks = async (imagesWorks) => {
    loading.value = true;
    const { data, status } = await store.dispatch("updateImagesWorks", imagesWorks)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getListByIdWorks();
    closeDialog();
  }

  const removeImagesWorks = async (user) => {
    const val = await store.dispatch("generateConfirmDialog", {});
    if (!val) return;
    const { data, status } = await store.dispatch("removeImagesWorks", user)
    if (status != 200 && status != 201 && status != 204) return;
    await getListByIdWorks();
  }

  const getListByIdWorks = async () => {
    loading.value = true;
    const { data, status } = await store.dispatch('getListByIdWorks', id_work);
    loading.value = false;
    if (status != 200 && status != 201 && status != 204) return;
    imagesWorks.value = data;
  }
  getListByIdWorks();

  return {
    dialog,
    imagesWorksEdited,
    imagesWorks,
    loading,
    // computed
    titleDialog,
    subtitleDialog,
    // methods
    openDialog,
    openEdit,
    closeDialog,
    // crud
    createImagesWorks,
    updateImagesWorks,
    openRemove: removeImagesWorks,
    getListByIdWorks
  };

}

export default useImagesWorks;
